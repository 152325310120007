import * as React from "react"
import tw from "twin.macro"

const Footer = () => {
  const FooterRoot = tw.footer`flex items-center justify-center px-5 py-12 text-greyDark`
  return (
    <FooterRoot>
      <span>©{new Date().getFullYear()} Powerhouse. All rights reserved.</span>
    </FooterRoot>
  )
}

export default Footer
