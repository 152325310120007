import * as React from "react"
import tw from "twin.macro"
import Emblem from "../images/emblem.svg"

const Header = () => {
  const HeaderRoot = tw.header``
  const HeaderSection = tw.div`flex items-center justify-center px-5 pt-5 pb-6 text-greyDark first:(border-b border-greyLight py-5)`
  const HeaderBrand = tw.img``
  return (
    <HeaderRoot>
      <HeaderSection>
        <a href="https://www.powerhouse.je" target="_blank" rel="noreferrer">
          <HeaderBrand src={Emblem} width="112" height="50" alt="Powerhouse" title="Powerhouse" />
        </a>
      </HeaderSection>
      <HeaderSection>
        <span>Tap to shop now or learn more</span>
      </HeaderSection>
    </HeaderRoot>
  )
}

export default Header
