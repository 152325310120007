import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import tw from "twin.macro"

export default function IndexPage({ data }) {
  const Section = tw.section``
  const Container = tw.div`container px-2.5 sm:(px-5) !max-w-[940px]`
  const Posts = tw.div`grid grid-cols-3 gap-2.5 sm:(gap-5)`
  const Post = tw.div`flex border border-greyLight`
  return (
    <Layout>
      <Helmet>
        <title>Instagram | @powerhouse.je</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Section>
        <Container>
          <Posts>
            {data.posts.nodes.map((post, i) => {
              const Cover = getImage(post.fields.image?.localFile)
              return (
                <Post key={i}>
                  <a href={post.fields.link} target="_blank" rel="noreferrer">
                    <GatsbyImage image={Cover} alt={post.title} title={post.title} />
                  </a>
                </Post>
              )
            })}
          </Posts>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    posts: allWpSingleInstagram(sort: { order: DESC, fields: date }) {
      nodes {
        title
        fields: postInstagram {
          link
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 300
                  height: 300
                  placeholder: BLURRED
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`
